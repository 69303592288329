@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap');

* {
    margin: 0;
    padding: 0;
    list-style-type: none;
    text-decoration: none !important;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

/* font-family */
body {
    font-family: "quicksand";
}

.ff_yeseva {
    font-family: '';
}


:root {
    /* ==============font-sizes ===========*/
    // --fs_xxl:;
    --fs_xl: 52px;
    --fs_lg: 32px;
    --fs_md: 24px;
    --fs_sm: 18px;
    --fs_xsm: 16px;
    --fs_2xsm: 14px;
    --fs_3xsm: 12px;
    /* ==============colors-================ */
    --color_primary: #FE4D00;
    --color_secondary: #444444;
    --color_white: #ffffff;
    --color_black: #000000;
    --color_dark: #112211;
    --color_success: #1C1B1F;
    --color_info: #212121;
}

/* font-size */
.my-2 {
    margin-top: -1.5rem!important;
     margin-bottom: .5rem!important;
}
span.view-cal-btn.d-flex.fw_bold.my-2 {
    cursor: pointer;
}
.view-cal-btn svg {
    width: 20px;
    height: 24px;
}
.fs_xxl {
    font-size: var(--fs_xxl);
}

.fs_xl {
    font-size: var(--fs_xl);
}

.fs_lg {
    font-size: var(--fs_lg);
}

.fs_md {
    font-size: var(--fs_md);
}

.fs_sm {
    font-size: var(--fs_sm);
}

.fs_xsm {
    font-size: var(--fs_xsm);
}

.fs_2xsm {
    font-size: 14px;
}

.fs_3xsm {
    font-size: var(--fs_3xsm);
}

/* colors */
.color_primary {
    color: var(--color_primary);
}

.color_secondary {
    color: var(--color_secondary) !important;
}

.color_white {
    color: var(--color_white) !important;
    cursor: pointe
}

.color_black {
    color: var(--color_black) !important;
}

.color_dark {
    color: var(--color_dark) !important;
}

.color_success {
    color: var(--color_success) !important;
}

.color_info {
    color: var(--color_info) !important;
}

/* font-weight */
.fw_bold {
    font-weight: 700;
}

.fw_semi_bold {
    font-weight: 600;
}

.fw_md {
    font-weight: 500;
}

.fw_regular {
    font-weight: 400;
}

.fw_light {
    font-weight: 300;
}

/* nav section css code */
.logo_bg {
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 50px 50px;
    width: 300px;
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    z-index: 99;
}

/* hero section css code */

.hero_sec {
    padding: 70px 0 0;
    max-width: 95%;
    margin: 0 auto;
}

.listing_bg {
    background: #FFFFFF;
    box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    height: auto;
    left: 0;
    right: 0;
    max-width: 986px;
    margin: 0 auto;
    top: 60%;
    position: absolute;
}

.over_scrol::-webkit-scrollbar {
    width: 3px;
}

.over_scrol::-webkit-scrollbar-track {
    background-color: #F4E5E5;
}

.over_scrol::-webkit-scrollbar-thumb {
    background-color: #910000;
}

select::-webkit-scrollbar {
    width: 3px;
}

select::-webkit-scrollbar-track {
    background-color: #F4E5E5;
}

select::-webkit-scrollbar-thumb {
    background-color: #910000;
}

.over_scrol {
    overflow-y: auto;
    height: 440px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
}

.field_bg {
    background: rgba(145, 0, 0, 0.1);
    border-radius: 10px;
    margin-top: 14px;
}

.tour_nme {
    outline: none;
    border: none;
    border-bottom: 1px solid rgba(29, 28, 43, 0.5);
    background: transparent;
}

.drop {
    background: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid rgba(29, 28, 43, 0.5);
    border-radius: 0px;
}

.create_btn {
    background: #910000;
    border-radius: 30px;
    padding: 12px 0px;
    cursor: pointer;
    max-width: 170px;
    margin: 32px auto;
}

.listing_cont_bg {
    background: rgba(41, 204, 57, 0.07);
    border: 1px solid #29CC39;
    border-radius: 5px;
    margin-bottom: 14px;
    left: 0;
    right: 0;
    cursor: pointer;
}

/* guide sec css code */
.guide_sec {
    max-width: 95%;
    margin: 0 auto;
    margin-top: 450px;
}

.over_scrol_guide {
    overflow-y: scroll;
    height: 420px;
}

.form-check-input:checked {
    background-color: #910000;
    border-color: #910000;
}

.form-check-input:focus {
    box-shadow: none;
}

.listing_bg_guide {
    background: #FFFFFF;
    box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    height: 560px;
    left: 0;
    right: 0;
    max-width: 986px;
    margin: 0 auto;
    top: 60%;
    position: absolute;
}

.calender {
    border: 1px solid #cdc6c6 !important;
    border-radius: 5px;
    width: 100% !important;
    height: 500px;

}

.react-calendar button {
    padding: 33px;
}

.react-calendar__tile--now {
    border-radius: 60px;
    padding: 0px !important;
}

.react-calendar__tile--active {
    background: #dc0000 !important;
    color: white;
    border-radius: 60px;
    padding: 0px !important;
}

.color_black .py-2 {
    padding-bottom: 1.2rem !important;
}

.rbc-today {
    background-color: rgba(145, 0, 0, 0.07) !important;
}

.rbc-toolbar button:focus {
    color: #373a3c;
    background-color: rgba(145, 0, 0, 0.07) !important;
}

.rbc-btn-group {
    border-color: 2px solid #910000 !important;
}

@media(min-width:1200px) and (max-width:1280px) {
    .green-btn-scnd {
        margin-left: 4px;
    }

    .green-btn-frst span,
    .green-btn-scnd span {
        padding: 5px 2px;
    }

    .yellow-inner {
        margin: 10px 2px 0px 3px;
    }

}


/* media query css code */
@media only screen and (max-width:991px) {
    .listing_bg {
        background: #FFFFFF;
        height: auto;
        position: static;
        margin-top: 20px;
    }

    .guide_sec {
        margin-top: 40px;
    }

    .listing_bg_guide {
        background: #FFFFFF;
        height: auto;
        position: static;
        margin-top: 20px;
    }
}

@media only screen and (max-width:499px) {

    .logo_bg {
        border-radius: 0px 0px 10px 10px;
        width: 130px;
        height: 60px;
    }

    .hero_sec {
        padding: 20px 0 0;
    }

    .fs_sm_md {
        font-size: var(--fs_md);
    }

}

.sap-error-val {
    border-color: #ff4d4d !important;
}

.sap-error-val:after {
    content: url("data:image/svg+xml,%3C!-- Generator: Adobe Illustrator 25.2.0, SVG Export Plug-In --%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 20 20' style='overflow:visible;enable-background:new 0 0 20 20;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FF4D4D;%7D%0A%3C/style%3E%3Cdefs%3E%3C/defs%3E%3Cg%3E%3Cpath class='st0' d='M10.01,0C4.5-0.01,0,4.49,0,10.02C0,15.52,4.47,20,9.98,20c5.52,0,10.01-4.46,10.02-9.95 C20.01,4.54,15.51,0.01,10.01,0z M9.95,18.77c-4.8,0-8.74-3.96-8.73-8.78c0-4.95,4.18-8.83,8.92-8.76 c4.72,0.07,8.64,4.02,8.64,8.84C18.77,14.84,14.78,18.77,9.95,18.77z'/%3E%3Cpath class='st0' d='M9.39,11.07c0,0.54,0.2,0.79,0.61,0.78c0.48-0.02,0.6-0.33,0.6-0.75c0-1.98,0-3.96,0-5.94 c0-0.41-0.11-0.73-0.59-0.75C9.59,4.39,9.4,4.64,9.39,5.18c0,0.97,0,1.95,0,2.92C9.39,9.09,9.39,10.08,9.39,11.07z'/%3E%3Cpath class='st0' d='M9.98,13.47c-0.5,0.01-0.88,0.4-0.88,0.91c0,0.53,0.4,0.93,0.92,0.92c0.5-0.01,0.87-0.39,0.88-0.91 C10.89,13.86,10.5,13.46,9.98,13.47z'/%3E%3C/g%3E%3C/svg%3E%0A");
    position: absolute;
    left: 120px;
}

.submit-button {
    margin: 0 0 0 10px;
}

.submit-button button {
    color: #fff;
    padding: 10px 30px;
    border-radius: 30px;
    background-color: #910000;
    border: none;
}

select {
    width: 100%;
    word-wrap: normal;
    padding: 5px 0px;
    border-radius: 10px;
    cursor: pointer;
}

.calender {
    height: 400px;
    ;
}

.listing_bg_guide {
    height: 550px;
}

.react-calendar__tile--active {
    background: #910000 !important;
    border-radius: 10%;
}

.react-calendar button {
    padding: 15px;
}

.to-start-time {
    width: 100%;
    max-width: 50px;
    margin: 0 15px 0 0;
}

.to-end-time {
    max-width: 50px;
    width: 100%;
    margin: 0 15px 0 0;
}
.time-status {
    max-width: 50px;
    width: 100%;
    margin: 0 15px 0 0;
}

.time-to {
    display: flex;
}

.time-from {
    display: flex;
}

.time-start-end {
    display: flex;
    justify-content: space-between;
}

.listing_cont_bg.active {
    background-color: rgb(145 0 0 / 7%);
    border-color: #910000;
}

.react-calendar__tile:enabled:hover {
    background-color: #e6e6e6;
    border-radius: 10%;
}

.listing_cont_bg {
    transition: all 0.3s ease-in-out;
    font-family: poppins;
}

.listing_cont_bg h3 {
    font-size: 18px;
    line-height: 20px;
}

.guide-list {
    padding: 10px 0;
    min-width: 250px;
}

.guide-main-list {
    display: flex;
    align-items: center;
    margin: 10px 0 0 0;
}

.color_black.fw_bold.fs_lg.fs_sm_md.mt-3.mt-lg-0 {
    margin-bottom: 15px;
}


// sahil scss
.rbc-time-content {
    display: none !important;
}

.rbc-row-bg {
    height: 100vh !important;
}

.rbc-label {
    display: none !important;
}

.rbc-calendar {
    width: 100%;
    padding: 15px;
    min-height: 100vh !important;
}

.rbc-time-view {
    border-bottom: 0 !important;
    height: 100vh !important;
    min-height: 100vh !important;
}

.rbc-time-header-content {
    border-left: 0 !important;
}

.sti-tourer-img {
    height: 50px;
    width: 50px;
    overflow: hidden;
    border-radius: 50%
}

.sti-tourer-img img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.sap-tour-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center
}

.sti-tourer-left {
    margin: 0
}

.sti-tourer-right {
    line-height: normal;
    font-size: 16px
}

.sti-tourer-heading {
    font-weight: 600;
    margin: 0 5px 0 0
}

.rbc-event {
    background-color: transparent !important;
    color: #000 !important;
    padding: 0 !important;
    border-radius: 0 !important;
}

.sap-tour-head {
    background-color: transparent;
    border-radius: 5px;
    padding: 7px;
    border: 1px solid transparent;
    margin: 0 0 7px 0;
}

.sap-tour-head.Arena {
    background-color: rgb(145 0 0 / 15%);
    border-color: #910000
}

.sap-tour-head.UG {
    background-color: rgb(124 57 213 / 15%);
    border-color: #7c39d5
}

.sap-tour-head.Ordinario {
    background-color: rgb(77 199 98 / 15%);
    border-color: #4dc762
}

.sap-tour-head.Frontend {
    background-color: rgb(80 140 183 / 15%);
    border-color: #508cb7
}

.rbc-today {
    background-color: rgb(0 0 0 / 5%);
}

.sti-tourer-name {
    font-weight: 600;
}

.rbc-toolbar-label {
    font-weight: 600;
}

.rbc-toolbar button {
    text-transform: capitalize;
    font-weight: 600;
    font-size: 14px;
}


// kiran today 17-july-2023
.green-inner {
    background: rgb(41 204 57 / 5%);
    border-radius: 5px;
    border: 1px solid #29CC39;
    padding: 2px 2px 2px;
    margin: 10px 5px 0px 5px;
}

.orange-inner {
    background: rgba(255, 102, 51, 0.05);
    border: 1px solid #FF6633;
    border-radius: 5px;
    padding: 2px 2px 2px;
    margin: 10px 5px 0px 5px;
}

.green-inner {
    background: rgb(41 204 57 / 5%);
    border-radius: 5px;
    border: 1px solid #29CC39;
    padding: 2px 2px 2px;
    margin: 10px 5px 0px 5px;
}

.yellow-inner {
    background: rgb(213 188 13 / 5%);
    border-radius: 5px;
    border: 1px solid #eecd10;
    padding: 2px 2px 2px;
    margin: 10px 5px 0px 5px;
}

.skyblue-inner {
    background: rgba(41, 204, 57, 0.05);
    border-radius: 5px;
    border: 1px solid #508cb7;
    padding: 2px 2px 2px;
    margin: 10px 5px 0px 5px;
}

.first-green-col {
    display: flex;
}

.img-clm img {
    width: 30px;
    height: 30px;
    border-radius: 8px;
    object-fit: cover;
}
.react-datepicker-popper {
    z-index: 9999;
}
.h1, h1 {
    font-size: 1.5rem;
    font-size: large;
}
// .react-datepicker-wrapper {
//     display: block;
//     margin: 10px 0 0 15px;
// }
// .react-datepicker-wrapper input {
//     padding: 4px;
//     font-size: 14px;
// }
.name-clm {
    font-size: 10px;
    font-weight: 700;
    display: flex;
    align-items: center;
    padding-left: 8px;
    text-transform: capitalize;
}

.calendar-buttons {
    display: flex;
    padding-top: 0px;
    justify-content: space-around;
}
.name-clm .assign-guide {
    width: 100%;
}

.orange-sec .green-btn-frst span,
.orange-sec .green-btn-scnd span {
    background: #FF6633;
}

.yellow-sec .green-btn-frst span,
.yellow-sec .green-btn-scnd span {
    background: #eecd10;
}

.blue-sec .green-btn-frst span,
.blue-sec .green-btn-scnd span {
    background: #508cb7;
}

.green-btn-frst span,
.green-btn-scnd span {
    background: #29CC39;
    color: #fff;
    font-size: 10px;
    padding: 5px 5px;
    border-radius: 5px;
    font-family: 'Quicksand';
    font-weight: 700;
    text-decoration: none;
}

.green-btn-scnd {
    margin-left: 8px;
}

.rbc-header {
    color: #000 !important;
}

.rbc-toolbar .rbc-toolbar-label {
    color: #000 !important;
}

.set-guide-tb {
    display: flex;
    justify-content: space-between;
    padding: 0px 20px 0px 0px;
}

span.small-calendar svg {
    color: #910000;
    font-size: 28px;
    cursor: pointer;
}

.react-calendar__tile--now {
    background: #f4e5e5 !important;
}

.react-calendar__tile--active {
    color: #fff;
    background-color: #910000 !important;
    border-radius: 60px !important;
}

.over_scrol::-webkit-scrollbar {
    width: 5px !important;
}

.tour-message {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #910000;
    font-weight: 900;
    margin-top: 50%;
}

.rbc-row-segment {
    border-right: 1px solid #dddddd;
}

@media (max-width: 991px) {
    .listing_bg_guide .right-side {
        margin-top: 35px;
    }

    .listing_bg_guide {
        min-height: 670px !important;
    }

    .set-guide-tb {
        padding: 30px 0px 7px;
    }
}


@media (max-width:499px) {

    .to-start-time {
        margin: 0 3px 0 0;
    }

    .to-end-time {
        margin: 0 3px 0 0;
    }

    .react-calendar button {
        padding: 17px;
    }

}

@media (max-width:449px) {
    .right-side.guide-main-list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .listing_bg_guide {
        min-height: 690px !important;
    }

    .submit-button {
        margin-top: 20px;
    }

    .listing_bg_guide .right-side {
        margin-top: 24px;
    }

    .set-guide-tb {

        padding: 30px 0px 7px;
    }
}


.react-calendar__tile--active {
    height: 45px !important;
    width: 45px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
    flex: auto 0 !important;
}